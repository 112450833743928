<template>
<div 
  class="d-flex align-center flex-row"
>
  <!-- <div class="width-100">
    <v-chip
      label
      outlined
      class="d-flex align-center justify-center"
    >
      <v-icon size="20" :left="$vuetify.breakpoint.smAndUp">mdi-calendar</v-icon>
      <span class="font-weight-bold text-h6">
        <template v-if="selected_text !== 'personalizado'">
          <span class="hidden-xs-only">{{selected_text}}:</span>
        </template> 
        {{fecha_inicio | smartDate }} - {{fecha_fin | smartDate }}</span>
    </v-chip>
  </div> -->
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    :nudge-bottom="10"
    :max-width="$vuetify.breakpoint.xsOnly ? '350px' :'380px'"
    offset-y
    z-index="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        label
        outlined
        class="d-flex align-center justify-center width-100"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon size="20" :left="$vuetify.breakpoint.smAndUp">mdi-calendar</v-icon>
        <span class="font-weight-bold text-h6">
          <template v-if="selected_text !== 'personalizado'">
            <span>{{selected_text}}:</span>
          </template> 
          {{fecha_inicio | smartDate }} - {{fecha_fin | smartDate }}</span>
          <v-icon 
            size="22" 
            right
            v-text="menu ? 'mdi-menu-up' : 'mdi-menu-down' "
          />             
      </v-chip>
      <!-- <v-btn
        color="icono"
        class="ml-2"
        :class="{'pr-1': $vuetify.breakpoint.smAndUp}"
        outlined
        small
        v-on="on"
        v-bind="attrs"
      >               
        <v-icon size="22" :left="$vuetify.breakpoint.smAndUp">mdi-tune</v-icon>             
        <span class="hidden-sm-and-down">Consultar</span>
      </v-btn> -->
    </template>
    <v-card>
      <v-card-text class="px-5 pt-5 mb-1">
        <v-row class="px-2">
          <v-col cols="12" class="px-2 black--text subtitle--text font-weight-medium">        
            Rango de Fecha
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="selected_date" class="pt-0" hide-details>
              <v-row>
                <v-col cols="6" sm="6" class="pt-0">
                  <v-radio
                    label="Semanal"
                    value="semana"
                    color="icono"
                  >
                    <template #label>
                      <span class="blue-grey--text font-weight-medium">Semana</span>
                    </template>
                  </v-radio>
                </v-col>
                <v-col cols="6" sm="6" class="pt-0">
                  <v-radio
                    label="Quincenal"
                    value="Quincena"
                    color="icono"
                  >
                    <template #label>
                      <span class="blue-grey--text font-weight-medium">Quincena</span>
                    </template>
                  </v-radio>
                </v-col>
                <v-col cols="6" sm="6" class="pt-0">
                  <v-radio
                    label="Mensual"
                    value="Mes"
                    color="icono"
                  >
                    <template #label>
                      <span class="blue-grey--text font-weight-medium">Mes Actual</span>
                    </template>
                  </v-radio>
                </v-col>
                <v-col cols="6" sm="6" class="pt-0">
                  <v-radio
                    label="Trimestre"
                    value="Trimestre"
                    color="icono"
                  >
                    <template #label>
                      <span class="blue-grey--text font-weight-medium">Trimestre</span>
                    </template>
                  </v-radio>
                </v-col>
                <v-col cols="12" sm="12" class="pt-0">
                  <v-radio
                    label="Personalizado"
                    value="personalizado"
                    color="icono"
                  >
                    <template #label>
                      <span class="blue-grey--text font-weight-medium">Personalizado</span>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" 
            class="d-flex"
            :class="{'flex-row align-center': $vuetify.breakpoint.smAndUp, 'flex-column justify-center': $vuetify.breakpoint.xsOnly}"
            >
            <div>
              <label-form text="Inicio" required/>
              <fecha-input 
                v-model="range_inicio" 
                color="blue-grey lighten-5"
                hiddenMsg
                :disabled="selected_date != 'personalizado'"/>
            </div>
            <div class="ml-3">
              <label-form text="Final" required/>
              <fecha-input 
                v-model="range_fin" 
                hidden-msg
                color="deep-orange lighten-5" 
                :disabled="selected_date != 'personalizado'"
              /> 
            </div>
          </v-col>
        </v-row>
        <v-row v-if="error.active">
          <v-col cols="12" class="pt-0">
            <span style="width:200px" class="error--text caption font-italic" v-text="error.msg"></span>
          </v-col>        
        </v-row>
        <v-row class="px-2">
          <v-col cols="12" class="px-2 black--text subtitle--text font-weight-medium">        
            Estatus
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
             <v-checkbox
              v-model="status" 
              class="mt-0"               
              label=""
              color="icono"
              hide-details
              value="1"
            >
              <template #label>
                <span class="blue-grey--text font-weight-medium">Procesado</span>
              </template>
            </v-checkbox>
            <v-checkbox 
              v-model="status"  
              class="mt-0"              
              label=""
              color="icono"
              hide-details
              value="0"
            >
              <template #label>
                <span class="blue-grey--text font-weight-medium">Sin Procesar</span>
              </template>
            </v-checkbox>
            <v-checkbox 
              v-model="status" 
              class="mt-0"               
              label=""
              color="icono"
              hide-details
              value="2"
            >
              <template #label>
                <span class="blue-grey--text font-weight-medium">Anulado</span>
              </template>
            </v-checkbox>
          </v-col>
          <!-- <v-col cols="12" sm="4" class="py-0">
          </v-col>
          <v-col cols="12" sm="4" class="py-0">
          </v-col>
          <v-col cols="12" sm="4" class="py-0">
          </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              outlined
              block
              color="blue-grey"
              small
              @click="menu = false"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              color="primary"
              block
              small
              @click="consultarDatos"
            >
              <v-icon left size="22">mdi-magnify</v-icon>
              Consultar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ConsultarRangoFecha',
  components:{
    FechaInput: () => import(
      /* webpackChunkName: "fecha-input" */
      '@/widgets/FechaInput'
    ),
  },
  props:{
    fecha: {
      type: Object,
      default: () => ({
        inicio: '',
        final: '', 
      }) 
    },
    allItems:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      fecha_inicio: '',
      fecha_fin: '', 
      range_inicio: moment().format('YYYY-MM-DD'),
      range_fin: moment().format('YYYY-MM-DD'),
      selected_date: 'semana',
      selected_text: 'semana',
      error:{
        active: false,
        msg: ''
      },
      menu: false,
      status: '',
    }
  },
  created(){
    this.fecha_inicio = moment().startOf('week').day(1).format("YYYY-MM-DD");
    this.fecha_fin = moment().format('YYYY-MM-DD');
  },
  methods:{
    consultarDatos() {
      this.error ={
        active: false,
        msg: ''
      };
      // this.fecha_fin = '';
      // this.fecha_inicio = '';
      switch (this.selected_date) {
        case 'todos':
          this.fecha_inicio = '';
          this.fecha_fin = '';
          break;
        case 'semana':
          this.fecha_inicio = moment().startOf('week').day(1).format("YYYY-MM-DD");
          this.fecha_fin = moment().format("YYYY-MM-DD");
          break;
        case 'Quincena':
          this.fecha_inicio = moment().subtract(15, 'days').format("YYYY-MM-DD");
          this.fecha_fin = moment().format("YYYY-MM-DD");
          break;
        case 'Mes':
          this.fecha_inicio = moment().startOf('month').format("YYYY-MM-DD");
          this.fecha_fin = moment().format("YYYY-MM-DD");
          break;
        case 'Trimestre':
          this.fecha_inicio = moment().subtract(3, 'months').format("YYYY-MM-DD");
          this.fecha_fin = moment().format("YYYY-MM-DD");
          break;
        case 'personalizado':
          if(moment(this.range_inicio).isSameOrBefore(this.range_fin)){
            this.fecha_inicio = this.range_inicio;
            this.fecha_fin = this.range_fin;
          }
          else{
            this.error ={
              active: true,
              msg: 'La fecha de inicio debe ser menor que la fecha final'
            };
            return;
          }
          break;
        default:
          break;
      }
      this.selected_text = this.selected_date;
      this.menu = false;
      this.$emit('consultar', {ini: this.fecha_inicio, fin: this.fecha_fin, estatus: this.status});
    }
  },
}
</script>
<style lang="sass">
.width-100
  width: 100%
</style>
